import { SigpacServiceService } from './sigpac-service.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import * as firebaseui from 'firebaseui';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { FirebaseApp } from '@angular/fire/compat';
import * as PolyUtil from 'google-maps-polyutil';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root'
})
export class VisorFirebaseService {

  premium:Boolean;

  sendEmailVerification() {
    var actionCodeSettings = {
        url:'http://localhost:4200/pages/emailverification?'+this.angularFire.auth().currentUser.email,
        handleCodeInApp: true,
    };
    this.angularFire.auth().currentUser.sendEmailVerification(actionCodeSettings)
  }

  ui:any;

  constructor(protected angularFire: FirebaseApp,private afs: AngularFirestore,
    private analytics: AngularFireAnalytics,

    private sigpacservice: SigpacServiceService) {


   // firebase.default.initializeApp(environment.firebase);

    console.log("Inicializamos FIREBASE")
    this.ui = new firebaseui.auth.AuthUI(angularFire.auth());
  }

  getUser():Observable<any>{
    return new Observable((observer) => {

      this.angularFire.auth().onAuthStateChanged((user) => {
        observer.next(user);

        if(user == null) {
         // console.log("Desactivamos premium")
          this.premium = null;
        }
      });


      observer.next(firebase.default.auth().currentUser);

    });
  }

  checkPremium():Observable<Boolean>{
    return new Observable((observer) => {
        if(this.premium != null){
          observer.next(this.premium);
        }else{
          this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).ref.get().then((doc) => {
            this.premium = (doc.data() as any).premium;
            observer.next(this.premium);
        });
        }
    });
    }

  initAuthUi(container:string):Observable<any>{

    return new Observable((observer) => {

      const appleProvider = new firebase.default.auth.OAuthProvider('apple.com');
      appleProvider.addScope('email');
      appleProvider.addScope('name');
      this.ui.start(container, {
        signInOptions: [
          firebase.default.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.default.auth.GoogleAuthProvider.PROVIDER_ID,
          'apple.com',
        ],
        callbacks: {
          signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            console.log(authResult);
            observer.next(firebase.default.auth().currentUser);
            return false;
          }
        },
        // Other config options...
      });
    })

  }



  obtenerParcelas() : Observable<any>{
    this.analytics.logEvent('obtener_parcelas')
    return new Observable((observer) => {
      let userid = this.angularFire.auth().currentUser.uid;
     // userid = 'vUxmAVDK7ZM6x2HjV35PHtpNrvG2';

      this.afs.collection('users').doc(userid).collection('fields').get().subscribe(async (snapshot)=>{
        let parcelas = [];

        for(const doc of snapshot.docs){
          try{
            let parcela = JSON.parse(JSON.stringify(doc.data()));
            parcela.id = doc.id;
            if(parcela.path != null){

              //console.log("Generamos geometria")
              parcela.geometry = this.generateGeometry(parcela.path);
              //console.log(parcela.geometry);

            }else{
              //console.log("Obtenemos geometria SIGPAC")
              const parcelasigpac = await this.sigpacservice.getParcela(parcela.params);
              parcela.geometry = parcelasigpac.geometry;
              //console.log(parcelasigpac);
            }

            this.generateParcelaParams(parcela);

            parcelas.push(parcela);
          } catch(error){

            console.error('error al obtener parcelas ',error);
          }

        }
        observer.next(parcelas)
      });
    })

  }

  eliminarRegistroDiario(idParcela:string,idRegistro: string) :Observable<any>{
    this.analytics.logEvent('emliminar_registro_diario')
    return new Observable((observer) => {
      this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').doc(idRegistro).delete().then((confirmed) =>{
          observer.next(confirmed);
      });
    });
  }

  actualizaRegistroDiario(idParcela:string, diario: any) :Observable<any>{
    this.analytics.logEvent('actualizar_registro_diario')
    return new Observable((observer)=>{
        let idRegistro = diario.id;
        delete diario.id;
        diario.cantidad = +Number(diario.cantidad)
        diario.afectado = +Number(diario.afectado)
        this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').doc(idRegistro).update(diario).then((updated)=>{
        observer.next(updated);
      });
    });
  }

  creaRegistroDiario(idParcela:string,diario: any) :Observable<any>{
    this.analytics.logEvent('crear_registro_diario')
    return new Observable((observer)=>{
      diario.cantidad = Number(diario.cantidad)
      diario.afectado = Number(diario.afectado)
        this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').add(diario).then((diarioCreated) => {
        diario.id = diarioCreated.id;
        observer.next(diario);
      });
    });
  }

  obtenerDiario(idParcela: string)  :Observable<any>{
    this.analytics.logEvent('obtener_diario')
    return new Observable((observer) => {
      this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').get().subscribe((snapshot)=>{
        let diario = [];
        snapshot.forEach((doc)=>{
          let registro = JSON.parse(JSON.stringify(doc.data()));
          registro.id = doc.id;


          diario.push(registro);
        })
        observer.next(diario)
      });
    })

  }
  generateParcelaParams(parcela: any) {
    let parcParams = parcela.params.split(',');
    parcela.provincia = parcParams[0];
    parcela.municipio = parcParams[1];
    parcela.agregado = parcParams[2];
    parcela.zona = parcParams[3];
    parcela.poligono = parcParams[4];
    parcela.parcela = parcParams[5];
    parcela.recinto = parcParams[6];
  }

  generateGeometry(path: string):any{
    let geometry = {
      type:'Polygon',
      coordinates:[[]],

    }

    if(path.split("|").length > 2){
      //Es iOS

      try{
        path.split("|").forEach((coord) => {
          let cp = coord.split(':');
          if (cp.length != 2){
            throw new Error(('No se puede generar coordenadas'));
          }
          geometry.coordinates[0].push([Number(cp[1]),Number(cp[0])]);
        })
      } catch(e) {
        let coords = PolyUtil.decode(path).reverse();
        coords.forEach((coordp) => {

          geometry.coordinates[0].push([coordp.longitude,coordp.latitude])
        });
      }
    } else {
      let coords = PolyUtil.decode(path).reverse();
      coords.forEach((coordp) => {

        geometry.coordinates[0].push([coordp.longitude,coordp.latitude])
      });
    }




    return geometry;
  }
  closeSession() {
    this.angularFire.auth().signOut();
  }
}
